<template>
  <div class="articles">
    <div>
      <!-- 左侧开始 -->
      <div class="bordershadow left">
        <!-- 类目标题开始 -->
        <div class="titleRow">
          <div class="title">{{$t("UNESCO_Centres")}}</div>
        </div>
        <!-- 类目标题结束 -->
        <div class="map" v-if="data.length">
          <!-- 地图组件开始 -->
          <div class="maps">
            <div class="amap-page-container">
              <div class="toolbar">
                <!-- 当前坐标: {{ lng }}, {{ lat }} lang="en" -->
              </div>
              <el-amap
                vid="amapDemo"
                :center="center"
                :zoom="zoom"
                lang="en"
                class="amap-demo"
                :events="events"
                pitch-enable="false"
                :if="AMapLoaded"
              >
                <!-- :amap-manager="amapManager" -->
                <el-amap-marker
                  v-for="(marker, index) in markers"
                  :key="index"
                  :events="marker.events"
                  :position="marker.position"
                />
                <el-amap-info-window
                  v-if="window"
                  :position="window.position"
                  :visible="window.visible"
                  :content="window.content"
                  :offset="window.offset"
                  :close-when-click-map="true"
                  :is-custom="true"
                >
                  <div id="info-window">
                    <p>{{ window.name }}</p>
                    <!-- <div class="detail" @click="checkDetail">查看详情</div> -->
                  </div>
                </el-amap-info-window>
              </el-amap>
            </div>
          </div>
          <!-- 地图组件结束 -->
        </div>
      </div>
      <!-- 左侧结束 -->
      <!-- 右侧开始 -->
      <div class="bordershadow right">
        <!-- 类目标题开始 -->
        <div class="titleRow">
          <div class="title">{{$t("Person_Profile")}}</div>
          <div class="more">
            <!-- <router-link to="/News">
              {{$t("More")}}
            </router-link> -->
          </div>
        </div>
        <!-- 类目标题结束 -->
        <div class="person" v-if="person">
          <div class="top">
            <div class="left-top title-Row">
              <p>Name:</p>
              <p>Gender:</p>
              <p>Country:</p>
            </div>
            <div class="mid-top des-Row">
              <p>{{ person.name }}</p>
              <p>{{ person.gender }}</p>
              <p>{{ person.country }}</p>
            </div>
            <div class="right-top">
              <img :src="person.avatar" alt="" />
            </div>
          </div>
          <div class="bott">
            <div class="title-Row">
              <p>Education:</p>
            </div>
            <div class="des-Row">
              <p>{{ person.education }}</p>
            </div>
          </div>
          <div class="bott">
            <div class="title-Row">
              <p class="Introduction">Introduction:</p>
            </div>
            <div class="des-Row">
              <p>
                {{ person.introduction }}
              </p>
            </div>
          </div>
        </div>
        <div class="her" v-if="unescoMap.length">
          <div class="titleRow">
            <div class="title">{{$t("Her_Tracks")}}</div>
          </div>

          <div class="box">
            <el-scrollbar>
              <div class="block">
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in unescoMap"
                    :key="index"
                    :timestamp="activity.name"
                    type="primary"
                  >
                    <a :href="activity.article.fromlink" target="_blank">
                      {{ activity.date }}
                    </a>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
      <!-- 右侧结束 -->
    </div>
  </div>
</template>

<script>

import {lazyAMapApiLoaderInstance } from "vue-amap";

export default {
  name: "AmapPage",
  data() {
    const self = this;
    return {
      person: null,
      // amapManager,
      unescoMap: [], //有金纬度
      // 地图参数开始
      data: [],
      zoom: 3, //地图显示的缩放级别范围，在PC上，默认范围[3,18]，取值范围[3-18]；
      center: [], //地图中心点坐标值
      expandZoomRange: true,
      markers: [],
      windows: [],
      window: "",
      events: {
        click(e) {
          const { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
        },
      },
      lng: 0,
      lat: 0,
    };
  },
  //data数据结束

  mounted() {
    // console.log(lazyAMapApiLoaderInstance.load().then(),"111111111111")
    lazyAMapApiLoaderInstance.load().then(() => {
      // console.log(res,"111111111111")
           let lastname = localStorage.getItem("_AMap_raster");
      console.log(lastname, "lastnamelastnamelastnamelastnamelastname");

      // localStorage.removeItem("_AMap_raster");
      this.AMapLoaded = true;

      this.point();//地图
    });
  
  },
 
  methods: {
    async getData() {
      let id = this.$route.query.id;
      let url = "/data/api/c2cmap.do?action=person&id=" + id;
      await this.axios.get(url).then((res) => {
        let { events, person } = res.data.messages.data;
        this.unescoMap = events;
        this.person = person;
        if (events.length > 0) {
          this.center = [events[0].longitude, events[0].latitude];
          this.data = events.map((item) => {
            return {
              position: `${item.longitude},${item.latitude}`,
              name: item.name,
            };
          });
        }
      });
    },

    async point() {
      await this.getData();
      // console.log("22222222222");
      const markers = [];
      const windows = [];
      const that = this;
      this.data.forEach((item, index) => {
        // console.log("4444444444444");
        markers.push({
          position: item.position.split(","),
          // icon:item.url, //不设置默认蓝色水滴
          events: {
            mouseover() {
              // 方法：鼠标移动到点标记上，显示相应窗体
              that.windows.forEach((window) => {
                window.visible = false; // 关闭窗体
              });
              that.window = that.windows[index];
              that.$nextTick(() => {
                that.window.visible = true;
              });
            },
          },
        });
        windows.push({
          position: item.position.split(","),
          isCustom: true,
          offset: [115, 100], // 窗体偏移
          showShadow: false,
          visible: false, // 初始是否显示
          name: item.name,
        });
      });
      //  加点
      this.markers = markers;
      // 加弹窗
      this.windows = windows;
    },
    // checkDetail() {
    //   alert("点击了查看详情");
    // },
  },
};
</script>
<style>
.el-scrollbar__wrap {
  overflow-x: hidden !important;
}
</style>
<style lang="scss" scoped>
.articles {
  width: 100%;
  padding-top: 110px;
  background-color: #f7f8f8;
  margin-bottom: 30px;
  color: #444546;
  > div {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}

.box {
  width: 465px;
  height: 288px;
  background: white;
  margin: 0 auto;
  margin-top: 10px;
}
.el-scrollbar {
  height: 100%;
}
.el-scrollbar__wrap {
  overflow: scroll;
  width: 105%;
  height: 120%;
}
::v-deep .el-timeline-item__node--normal {
  left: 0px;
}

::v-deep .el-timeline-item__tail {
  left: 5px;
  color: #009fe3;
}

::v-deep .el-timeline-item__tail {
  border-left: 2px solid #009fe3;
}

.left {
  width: 685px;
  margin-right: 15px;
  .map {
    margin-top: 10px;
  }
}

.right {
  width: 500px;
  font-size: 14px;
  padding-bottom: 15px;
}
.person {
  background-color: #f1f3f4;
}

.top {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0px 15px;
}

.left-top {
  > p:nth-child(2) {
    padding: 10px 0px;
  }
}

.mid-top {
  width: 250px;
  padding-left: 10px;
}

.right-top {
  flex: 1;
  text-align: center;
  // border: 1px solid red;
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.bott {
  display: flex;
  // justify-content: space-between;
  padding: 0px 15px;
}

.title-Row {
  font-weight: 500;
  color: #222323;
  p {
    text-align: right;
    width: 100px;
  }
}

.des-Row {
  padding-left: 15px;

  p {
    font-weight: 400;
    color: #666868;
    text-align: left;
    padding-bottom: 10px;
    // max-height: 138px;
    // overflow: hidden;
    word-wrap: break-word;
  }
}

.Introduction {
  padding-bottom: 10px;
}

.her {
  margin-top: 20px;
}

// 地图样式开始
.amap-demo {
  width: 655px;
  height: 660px;
  padding-bottom: 15px;
}
.amap-page-container {
  position: relative;
}
#info-window {
  padding: 10px;
  margin-left: 30px;
  text-align: center;
  line-height: 21px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  .detail {
    width: 100%;
    height: 24px;
    color: #fff;
    background-color: #1a73e8;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
}
::v-deep .amap-copyright {
  display: none !important;
}
// 地图样式结束
</style>
